.column {
  display: flex;
  align-items: center;
  margin-top: 40px;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 768px) {
    margin-top: 10px;
    gap: 20px;
  }
}

.img {
  width: 75%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.description {
  width: 75%;
  line-height: 31px !important;
  font-size: 19px !important;

  @media (max-width: 768px) {
    width: 100%;
    line-height: 22px !important;
    font-size: 17px !important;
  }
}
