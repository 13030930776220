.radioWrapper {
  width: 100%;
  margin-top: 20px !important;

  .radioTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    letter-spacing: -0.011em;
    color: #000;
    margin-bottom: 20px;

    @media(max-width: 520px) {
      font-size: 18px;
    }
  }

  .radioList {
    gap: 20px;
    width: 100%;

    .radioItem {
      border: 1px solid #B6B6B6;
      padding: 10px 16px;
      border-radius: 8px;
      margin: 0px !important;
    }
  }
}
