.bgParent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 20px;
  height: 20px;
  gap: 5px;
  position: relative;
  cursor: pointer;

  .bgItem {
    width: 20px;
    height: 2px;
    border: 1px solid #000;
    border-radius: 4px;
    transition: all 0.3s ease;
  }

  .bgItemActive {
    position: absolute;
    width: 20px;
    height: 2px;
    border: 1px solid #000;
    border-radius: 4px;
    transition: all 0.3s ease;

    &:nth-child(1) {
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
}

.langBtn {
  display: flex;
  gap: 5px;

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #B1B1B1;
  cursor: pointer;
}

.activeLangBtn {
  display: flex;
  gap: 5px;

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #000;
  cursor: pointer;
}
