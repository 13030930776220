.voteCard {
  width: 100%;
  height: 150px;
  border-radius: 12px;
  position: relative;
  transition: all 0.3s ease;

  .insideVoteCard {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;

    .text {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      transition: all 0.3s ease;

      p {
        font-size: 20px;
        line-height: 140%;
        letter-spacing: -0.011em;
        transition: all 0.3s ease;
      }

      .items {
        width: 50%;
        height: 30px;
        background-color: #000;
        border: 1px solid #fff;
      }
    }

    .linkBtn {
      padding: 7.5px 16px;
      background-color: #fff;
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      text-decoration: none;
      border-radius: 4px;

      @media(max-width: 620px) {
        width: 100%;
        text-align: center;

        .text p {
          font-size: 16px;
          line-height: 118%;
        }
      }
    }

    @media(max-width: 620px) {
      align-items: center;
    }
  }
}
