@mixin standardLabel {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  text-transform: none !important;
  line-height: 150% !important;
  display: inline-block !important;
  align-items: center !important;
  letter-spacing: -0.011em !important;
  color: #000000 !important;
  padding: 20px 0px 0px 0px !important;
  min-width: 40px !important;
  transition: all 0.2s ease !important;
}

.label {
  @include standardLabel;
}

.activeLabel {
  @include standardLabel;
  font-weight: bold !important;
}

.tabs {
  @media (max-width: 1140px) {
    display: none !important;
  }
}

.selectTab {
  display: none !important;
  width: 100%;

  @media (max-width: 1140px) {
    display: block !important;
  }
}
