.slider {
  width: 100%;
}

.slide {
  width: calc(25% - 30px) !important;
  background-color: rgb(169, 169, 169);
  color: #fff;
  cursor: grab;
}

.partnerItem {
  width: 100%;
  height: 100%;
  display: block;
}

@media (max-width: 768px) {
  .slide {
    width: calc(40% - 10px) !important;
  }
}

@media (max-width: 414px) {
  .slide {
    width: calc(40% - 10px) !important;
  }
}