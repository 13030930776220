.voteList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(235px, 386px));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-top: 60px;

  @media (max-width: 840px) {
    justify-content: center;
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(235px, 100%));
  }
}

.voteListFull {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(49%, 49%));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 1080px) {
    justify-content: center;
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(49%, 100%));
  }

  @media (max-width: 840px) {
    justify-content: center;
    grid-row-gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(49%, 100%));
  }
}
