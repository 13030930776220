.grid {
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.newsImg {
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 206px;
  }
}

.allColumn {
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.column {
  display: flex;
  height: 144px;
  gap: 20px;
  overflow: hidden;
  &:hover {
    .description {
      color: #2f80ed;
    }
  }

  & img:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    height: 106px;
  }
}

.newsIcons {
  width: 100%;
}

.newsImages {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  transition: transform 0.5s;
  display: block;
}

.allNews {
  position: relative;
  height: 472px;
  width: 700px;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 100%;
    height: 212px;
  }
}

.titleFirst {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(255, 255, 255, 0) 40%
  );
  color: #fff;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  top: 0px;
  padding-bottom: 20px;
  justify-content: center;

  @media (max-width: 768px) {
    height: 97%;
  }
}

.titleMore {
  line-height: 30px;
  font-size: 24px !important;
  width: 85%;

  @media (max-width: 768px) {
    font-size: 14px !important;
    line-height: 17px !important;
    width: 90%;
  }
}

.titleSecond {
  width: 100%;
}

.description {
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 14px !important;
  }
}
