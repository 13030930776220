@import '../../global.scss';

.informationContainer {
  max-width: calc(100% - 200px);
  margin: 0 auto;

  @media (max-width: 820px) {
    max-width: 100%;
    margin: 0;
  }
}