@import '../../../global.scss';

@mixin textInCard {
  font-weight: 400;
  font-size: 22px !important;
  line-height: 150%;
  letter-spacing: -0.001em;
  color: #000000;
  font-size: 18px !important;
}

.decCard {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
  margin-top: 30px;
  border-bottom: 1px solid #EBEBEB;

  .flexRow {
    display: flex;
    width: 100%;

    @media (max-width: 770px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .number {
      font-weight: 500 !important;
      font-size: 22px !important;
      line-height: 150% !important;
      letter-spacing: -0.001em;
      min-width: 80px !important;
      color: #000000;
      text-align: center;

      @media (max-width: 770px) {
        text-align: start;
      }
    }

    .textWrapper {
      padding: 0 1.5rem 0 0.5rem;
      width: 100%;
      align-self: center;

      @media (max-width: 770px) {
        padding: 0;
      }

      .decreesTitle {
        @include textInCard;
      }

      .actsTitle {
        @include textInCard;
        cursor: pointer;
        transition: all 0.2s ease;

        &:hover {
          color: #2F80ED !important;
        }
      }

      .dateAndBtn {
        color: #989898;
        font-size: 18px;

        a {
          display: none;
        }

        @media (max-width: 770px) {
          display: flex;
          justify-content: space-between;
          align-items: center;

          a {
            color: #0770B8;
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
    }
  }

  .desktopImg {
    width: 32px;

    @media (max-width: 770px) {
      display: none;
    }
  }
}
