@import '../../global.scss';

.flexCenter {
  @include centeredFlex;
  width: 100%;
  height: 100%;

  .paperWrapper {
    width: 711px;
    border: 1px solid #000;
    padding: 30px;

    .title {
      font-weight: 500;
      font-size: 24px;
      line-height: 150%;
      letter-spacing: -0.011em;

      @media(max-width: 520px) {
        font-size: 18px;
      }
    }

    .btnWrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;

      @media(max-width: 520px) {
        justify-content: center;
      }
    }

    .btnWrapperBetween {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;
      gap: 10px;
    }
  }
}
