.teamList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(235px, 285px));
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media (max-width: 640px) {
    justify-content: center;
  }
}

.title {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 150% !important;
  letter-spacing: -0.011em !important;
  color: #000000 !important;
  margin: 70px 0 40px 0 !important;

  @media (max-width: 640px) {
    margin: 20px 0 20px 0 !important;
    font-size: 18px !important;
  }
}
