@import '../../global.scss';


.conferenceMainText {
  margin-bottom: 40px;
  margin-top: 20px;
}

.conferencePagination {
  margin-top: 120px;
  margin-bottom: 120px;

  @media screen and (max-width: 575px) {
    margin-top: 27px;
    margin-bottom: 40px;
  }
}
