.conferenceItem {
  width: calc(33% - 20px) !important;
  height: 386px;
}

.conferencesDescription {
  font-size: 20px !important;
  line-height: 25px !important;
  color: #303030 !important;
  max-width: 100% !important;
}

@media (max-width: 820px) {
  .conferenceItem {
    width: calc(50% - 20px) !important;
  }
}

@media (max-width: 575px) {
  .conferenceItem {
    height: min-content;
    width: calc(100% - 20px) !important;
  }
}
