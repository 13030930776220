.inputWrapper {
  width: 100%;
  position: relative;
  border: 1px solid #b6b6b6;
  border-radius: 8px;
  height: 50px;
  overflow: hidden;
  margin: 10px 0;
}

.titleRangeValue {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #000;
  position: absolute;
  right: 20px;
  top: 25%;
}
.titleRange {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #000;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(0, -50%);

  &:after {
    /* значение противоположное фону заполнителя */
    color: rgb(9, 21, 21);
    mix-blend-mode: difference;
  }
}

.inputRange {
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  transition: all 0.3s ease;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    display: none;
  }
}
