@import '../../../global.scss';

.test {
  background-color: #0770B8;
  width: 100%;
  margin-bottom: 80px;
  padding: 100px 0;
}

.container {
  @include spaceBetweenBlock;
  display: flex !important;
}

.textContent {
  width: 100%;
  max-width: 700px;
}

.testImg {
  display: block;
  max-width: 470px;
  max-height: 350px;
  width: 40%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.description {
  max-height: 300px;
  font-size: 26px !important;
  letter-spacing: -0.02em !important;
  line-height: 42px !important;
  color: #FFFFFF;
}

.testBtn {
  margin-top: 40px !important;
  color: #0770B8 !important;
  background-color: #fff !important;
  padding: 6px 36px !important;
  font-size: 16px !important;
  border-radius: 4px !important;
  text-transform: none !important;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }
}

@media (max-width: 1150px) {
  .description {
    line-height: 32px !important;
    font-size: 23px !important;
  }
}

@media (max-width: 820px) {
  .description {
    line-height: 26px !important;
    font-size: 18px !important;
  }

  .testBtn {
    margin-top: 20px !important;
    padding: 4px 18px !important;
    font-size: 12px !important;
  }
}

@media (max-width: 720px) {
  .test {
    padding: 30px 15px;
  }

  .container {
    display: block !important;
  }

  .description {
    line-height: 32px !important;
    font-size: 23px !important;
    margin: 15px 0 !important;
  }

  .testImg {
    max-width: 100%;
    width: 100%;
    max-height: 280px;
  }

  .testBtn {
    width: 100%;
    padding: 8px 48px !important;
  }
}

@media (max-width: 768px) {
  .test {
    margin-bottom: 40px;
  }
}

@media (max-width: 500px) {
  .testBtn {
    width: 100%;
    padding: 8px 0 !important;
  }

  .description {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .testImg {
    max-height: 164px;
  }
}

@media (max-width: 360px) {
  .test {
    padding: 16px 1px;
  }
}
