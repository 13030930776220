.customBtn,
.WideButton {
  border: 1px solid #2F80ED !important;
  color: #2F80ED !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;

  &:hover {
    background: #2F80ED !important;
    color: #fff !important;
  }

  @media (max-width: 520px) {
    width: 100%;
  }
}

.activeBtn {
  border: 1px solid #2F80ED !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
  background: #2F80ED !important;
  color: #fff !important;

  @media (max-width: 520px) {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .WideButton {
    width: 100% !important;
  }
}