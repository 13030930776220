/* COMPONENT YesNot.js customize radio button */
.css-hyxlzm {
  background: white;
  border-radius: 50%;
  transition: all 0.3s ease;

  & svg {
    opacity: 0;
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    left: 52%;
    top: 52%;
    transform: translate(-50%, -50%);
    width: 15px;
    height: 15px;
    background: url("./assets/icons/done.svg") center no-repeat;
    background-size: contain;
  }
}

.pagin {
  .swiper-pagination {
    position: unset;
  }
}
/* COMPONENT YesNot.js customize radio button */

/* COMPONENT SELECTS.JS */

body {
  .MuiPopover-root.MuiMenu-root.MuiModal-root
    > .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded {
    max-height: 50vh !important;
  }
}

/* COMPONENT SELECTS.JS */

/* PAGE Statistic.js to remove overflow hiddem from diagram */
.downloadDiagramWrapper {
  display: inline-block;
  width: 100%;
  .recharts-surface {
    overflow: initial;
  }
}

.MuiSelect-select {
  display: block;
  max-width: 119px;
}

.linkItem {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #303030;
  text-decoration: none;

  @media (max-width: 1200px) {
    font-size: 13px;
  }
}

.linksItem {
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #303030;
  text-decoration: none;

  @media (max-width: 1200px) {
    font-size: 13px;
  }
}

.linkItemForKy {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #303030;
  text-decoration: none;

  @media (max-width: 1200px) {
    font-size: 14px;
  }
}

.error {
  border-bottom: 1px solid #eb516d;
  background: red;
}

/* PAGE Statistic.js to remove overflow hiddem from diagram */
