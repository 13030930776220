@import '../../global.scss';

.conferenceList {
  @include spaceBetweenBlock;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

.conferenceItem {
  width: calc(33% - 20px) !important;
}

@media (max-width: 820px) {
  .conferenceItem {
    width: calc(50% - 20px) !important;
  }
}

@media (max-width: 575px) {
  .conferenceList {
    justify-content: center;
  }

  .conferenceItem {
    width: calc(100% - 20px) !important;
  }
}