@import '../../../global.scss';

.mainBlock {
  margin-bottom: 80px;
  display: flex !important;
  @include centeredFlex;
  flex-direction: column;
}

.active {
  display: none !important; // hides content if there is no data
}

@media (max-width: 768px) {
  .mainBlock {
    margin-bottom: 40px;
  }
}
