.notFound {
  margin: 0 auto;
  padding-top: 100px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statusCode {
  font-weight: 400;
  font-size: 160px;
  line-height: 195px;
  color: rgba(7, 112, 184, 1);
}

.text {
  color: #000;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 30px;
}


@media (max-width: 820px) {
  .notFound {
    padding-top: 60px;
    max-width: 320px;
  }

  .statusCode {
    font-size: 100px;
  }

  .text {
    font-size: 20px;
  }
}
