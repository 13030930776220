@mixin initialStyle {
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
}

.prevPath {
  @include initialStyle;
  color: #A5A5A5;
  border-bottom: 1px solid #A5A5A5;
}

.space {
  @include initialStyle;
  color: #A5A5A5;
}

.currentPath {
  width: max-content;
  overflow: hidden;
  color: #000;
  border-bottom: 1px solid #000;
}
