@import "../../../global.scss";

.hero {
  background: no-repeat center;
  background-size: cover;
  @include centeredFlex;
  flex-direction: column;
  height: calc(100vh - 160px);
  width: 100%;
  margin-bottom: 80px;

  @media (min-height: 950px) {
    height: 58vh;
  }

  @media (min-height: 1200px) {
    height: 40vh;
  }

  @media (max-width: 1051px) {
    height: 100vh;
  }
}

.heroContent {
  @include centeredFlex;
  justify-content: flex-start;
}

.logoText {
  line-height: 23px !important;
  margin-left: 10px !important;
  position: relative;
  max-width: 160px;
  font-size: 20px !important;
  color: #fff !important;

  &::before {
    content: "";
    top: 3px;
    left: -5px;
    position: absolute;
    width: 2px;
    height: 92%;
    background-color: #fe161f;
  }
}

.caption {
  max-width: 552px;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 45px !important;
  text-align: center;
  letter-spacing: 1px !important;
  color: #ffffff !important;
  margin-top: 45px !important;
  text-transform: uppercase;
}


@media (max-width: 820px) {
  .hero {
    margin-bottom: 40px;
  }

  .caption {
    margin-top: 20px !important;
    font-size: 16px !important;
    max-width: 360px;
    line-height: 22px !important;
  }
}

@media (max-width: 500px) {
  .hero {
    height: 50vh;
  }

  .caption {
    font-size: 12px !important;
    max-width: 290px !important;
  }

  .heroContent {
    & .logo {
      width: 73px;
    }

    & .logoText {
      line-height: 16px !important;
      margin-left: 5px !important;
      max-width: 100px !important;
      font-size: 13px !important;
    }
  }
}
