@mixin fontstandard {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 1px;
  color: #000000;
}

.newsImg {
  cursor: pointer;
  width: 100%;
  height: 150px;
  object-fit: cover;
  transition: transform 0.5s;
  display: block;
}

.mainNewsCard,
.mainInfoCard {
  display: flex;
  gap: 30px;
  width: calc(50% - 30px);

  .newsImg {
    width: min-content;
    min-width: 266px;
    height: 200px;
    object-fit: contain;
    cursor: default !important;
  }

  .descNews {
    width: 274px;

    & a {
      white-space: nowrap;
    }
  }

  .description {
    min-width: calc(50% - 30px);
    cursor: default !important;
    margin-bottom: 15px;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    width: min-content;
    max-width: 100%;

    & .descNews,
    & .descNews p {
      min-width: 100%;
    }

    & .newsImg {
      width: 100%;
    }
  }
}

.columnCard,
.columnCardInfo {
  display: flex;
  justify-content: center;
  gap: 23px;
  overflow: hidden;
  &:hover {
    .description {
      color: #2f80ed;
    }
  }
  & img:hover {
    transform: scale(1.1);
  }

  .descNews {
    width: 100%;
  }

  .description {
    margin-bottom: 15px;
    font-size: 18px !important;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px !important;
  }

  .newsImg {
    width: auto;
    min-width: 357px;
    height: 200px;
    display: block;
    transition: transform 0.5s;
  }
}

.mainNewsCard,
.infoCard,
.columnCard,
.flexCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 250px;
  overflow: hidden;

  &:hover {
    .description {
      color: #2f80ed;
    }
  }

  & img:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    max-width: none;
  }
}

.mainNewsCard {
  max-width: 360px;
  gap: 10px 30px;

  .newsImg {
    min-width: 357px;
    min-height: 200px;
    object-fit: cover;
  }
}

.columnCard .newsImg {
  object-fit: cover;
}

@media (min-width: 821px) {
  .mainNewsCard {
    flex-direction: row;
    align-items: flex-start;
    overflow: visible;

    .descNews {
      min-width: 800px;
    }
  }
}

.flexCardInfo .newsImg,
.columnCardInfo .newsImg {
  object-fit: contain;
}

.description {
  @include fontstandard;
  text-align: start;
  font-size: 14px !important;
  line-height: 20px !important;
  cursor: pointer;
}

@media (min-width: 414px) {
  .mainNewsCard .description {
    font-size: 24px !important;
    line-height: 40px !important;
  }
}

@media (max-width: 820px) {
  .description {
    margin: 10px 0 !important;
  }

  .flexCard {
    align-items: flex-start;
  }
}

@media (max-width: 575px) {
  .flexCard {
    display: block;
  }
}

@media (max-width: 414px) {
  .newsImg {
    width: 100%;
    height: 160px;
    object-fit: cover;
  }

  .description {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}
