.empty {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text {
  width: 100%;
  max-width: 382px;
  font-size: 32px !important;
  line-height: 45px !important;
  text-align: center;
}
