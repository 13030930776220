.employeeCard {
  width: 285px;
  height: 426px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .employeeImg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }

  .insideContent {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 16px 10px;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(255, 255, 255, 0) 40%
    );
    z-index: 1;
    gap: 13px;
    margin-bottom: 13px;

    .name {
      font-weight: 500;
      font-size: 21px;
      line-height: 30px;
      letter-spacing: 1px;
      color: #ffffff;
    }

    .position {
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      letter-spacing: 1px;
      color: #ffffff;
    }
    .contact {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 1px;
      color: #ffffff;
    }
  }
}
