.result {
  display: flex;
  gap: 30px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.projectImg {
  width: 300px;
  height: 220px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.title {
  font-size: 22px !important;
  font-weight: 500 !important;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 19px !important;
  }
}

.projectCard {
  display: flex;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.readBtn {
  border: 1px solid #2f80ed !important;
  border-radius: 4px !important;
  color: #2f80ed !important;
  background: #ffffff !important;
  text-transform: none !important;
  box-shadow: none !important;
  display: flex !important;
  height: 38px !important;

  &:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15) !important;
  }
}

.description {
  font-size: 18px !important;
  line-height: 31px !important;
  margin: 9px 0 !important;

  @media (max-width: 768px) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.column {
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}
