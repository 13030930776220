// VARIABLES
$main_color: #0093E6;
$primary_color: #1B1B1B;

// MIXINS
@mixin centeredFlex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin spaceBetweenBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin spaceAroundBlock {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

// FONTS

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  src: local(''),
    url('./assets/fonts/MontserratRegular/Montserrat-Regular.eot') format('eot'),
    url('./assets/fonts/MontserratRegular/Montserrat-Regular.svg') format('svg'),
    /* IE6-IE8 */
    url('./assets/fonts/MontserratRegular/Montserrat-Regular.ttf') format('ttf'),
    /* Safari, android, ios */
    url('./assets/fonts/MontserratRegular/Montserrat-Regular.woff2') format('woff2'),
    /* Legacy ios */
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  src: local(''),
    url('./assets/fonts/MontserratMedium/Montserrat-Medium.eot') format('eot'),
    url('./assets/fonts/MontserratMedium/Montserrat-Medium.svg') format('svg'),
    /* IE6-IE8 */
    url('./assets/fonts/MontserratMedium/Montserrat-Medium.ttf') format('ttf'),
    /* Safari, android, ios */
    url('./assets/fonts/MontserratMedium/Montserrat-Medium.woff2') format('woff2'),
    /* Legacy ios */
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  src: local(''),
    url('./assets/fonts/MontserratSemiBold/Montserrat-SemiBold.eot') format('eot'),
    url('./assets/fonts/MontserratSemiBold/Montserrat-SemiBold.svg') format('svg'),
    /* IE6-IE8 */
    url('./assets/fonts/MontserratSemiBold/Montserrat-SemiBold.ttf') format('ttf'),
    /* Safari, android, ios */
    url('./assets/fonts/MontserratSemiBold/Montserrat-SemiBold.woff2') format('woff2'),
    /* Legacy ios */
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  src: local(''),
    url('./assets/fonts/MontserratBold/Montserrat-Bold.eot') format('eot'),
    url('./assets/fonts/MontserratBold/Montserrat-Bold.svg') format('svg'),
    /* IE6-IE8 */
    url('./assets/fonts/MontserratBold/Montserrat-Bold.ttf') format('ttf'),
    /* Safari, android, ios */
    url('./assets/fonts/MontserratBold/Montserrat-Bold.woff2') format('woff2'),
    /* Legacy ios */
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: normal;
  src: local(''),
    url('./assets/fonts/MontserratExtraBold/Montserrat-ExtraBold.eot') format('eot'),
    url('./assets/fonts/MontserratExtraBold/Montserrat-ExtraBold.svg') format('svg'),
    /* IE6-IE8 */
    url('./assets/fonts/MontserratExtraBold/Montserrat-ExtraBold.ttf') format('ttf'),
    /* Safari, android, ios */
    url('./assets/fonts/MontserratExtraBold/Montserrat-ExtraBold.woff2') format('woff2'),
    /* Legacy ios */
}

/* WOFF FORMAT */

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/MontserratRegular/Monseratt-Regular-cyrillic.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./assets/fonts/MontserratRegular/Monseratt-Regular-cyrillic.woff') format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./assets/fonts/MontserratMedium/Monseratt-Medium-latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('./assets/fonts/MontserratMedium/Monseratt-Medium-cyrillic.woff') format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}


/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./assets/fonts/MontserratSemiBold/Monseratt-SemiBold-latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('./assets/fonts/MontserratSemiBold/Monseratt-SemiBold-cyrillic.woff') format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./assets/fonts/MontserratBold/Monseratt-Bold-latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./assets/fonts/MontserratBold/Monseratt-Bold-cyrillic.woff') format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('./assets/fonts/MontserratExtraBold/Monseratt-ExtraBold-latin.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('./assets/fonts/MontserratExtraBold/Monseratt-ExtraBold-cyrillic.woff') format('woff');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
