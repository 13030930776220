.dropWrapper {
  width: 100%;
  margin-top: 20px !important;
}

.dropTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #989898;
}

.error {
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid #eb516d;
  }
}
