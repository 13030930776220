.formHr {
  display: none;

  @media (max-width: 786px) {
    display: block;
    width: 85%;
  }
}

.searchBtn {
  background: #2f80ed;
  width: 90px;
  height: 35px;
  z-index: 2000000000000000;
  font-size: 18px;
  color: white;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.clearBtn {
  border: none;
  background: #fff;
}
