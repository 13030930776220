.projectCard {
  margin-top: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.readBtn {
  border: 1px solid #2f80ed !important;
  border-radius: 4px !important;
  color: #2f80ed !important;
  background: #ffffff !important;
  text-transform: none !important;
  box-shadow: none !important;
  display: flex !important;
  height: 38px !important;

  &:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15) !important;
  }
}

.column {
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}

.hr {
  border: "1px solid #DDDBDA";
}
