.textWrapper {
  display: flex;
  flex-direction: column;

  .textContent {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: 0 auto;

    & p {
      font-size: 18px;
      line-height: 31px;
    }

    @media screen and (max-width: 1150px) {
      width: 85%;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

}

.title {
  font-weight: 500;
}
