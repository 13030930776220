.stepsList {
  display: flex;
  gap: 10px;
  margin-top: 50px;

  @media(max-width: 520px) {
    justify-content: center;
  }

  .stepItem {
    width: 50px;
    height: 5px;
    border-radius: 8px;
    background-color: #B6B6B6;

    .stepNotActive {
      width: 0%;
      height: 100%;
      transition: all 0.2s ease;
    }

    .stepActive {
      transition: all 0.2s ease;
      width: 100%;
      height: 100%;
      background-color: #0770B8;
    }
  }
}
