.bigImg {
  width: 100%;
  height: 500px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 189px;
  }
}

.title {
  background: linear-gradient(to top,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(255, 255, 255, 0) 40%);
  color: #fff;
  position: absolute;
  height: 95%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  bottom: 0px;
  padding-bottom: 20px;
  justify-content: center;
  text-align: center;

  @media (max-width: 768px) {
    height: 87%;
  }
}

.titleMore {
  font-size: 30px !important;
  width: 80%;
  text-align: center;
  line-height: 35px !important;

  @media (max-width: 768px) {
    font-size: 14px !important;
    line-height: 15px !important;
    width: 98%;
  }
}

.description {
  display: flex;
  width: 70%;
  margin: 20px auto;
  flex-direction: column;

  & * {
    text-align: justify;
    font-family: 'Montserrat', sans-serif !important;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}
