@import "../../global.scss";

.footer {
  background: linear-gradient(180deg, #01366C 0%, rgba(1, 54, 108, 0.8) 100%);
  @include spaceAroundBlock;
  margin-top: 80px;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
}

.label {
  text-decoration: none;
  color: #fff;
}

.partnerLabel {
  cursor: pointer;
}

.icons {
  display: flex;
  align-items: center;
  margin-top: 13px;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 20px;
  }
}

.icon {
  @media (max-width: 768px) {
    width: 33px;
  }
}

.columns {
  display: flex;
  justify-content: space-between;
  color: #fff;
  margin-left: 0;
  margin-top: 10px;

  @media (max-width: 768px) {
    display: block !important;
    margin-top: 20px;
  }
}

.firstColumn {
  display: flex !important;
  flex-direction: column !important;
}

.secondColumn {
  @media (max-width: 768px) {
    margin-top: 20px;
  }
}

.thirdColumn {
  width: 16%;

  @media (max-width: 768px) {
    width: 229px;
    margin-top: 25px;
  }
}
