@import '../../global.scss';



.h1 {
  font-size: 45px;
  font-weight: 600;
  line-height: 35px;

}

.h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 40px;
}

.h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}

.h4 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

@media screen and (max-width: 720px) {
  .h1 {
    font-size: 30px;
    line-height: 25px;
  }

  .h2 {
    font-size: 22px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  .h3 {
    font-size: 16px;
    line-height: 18px;
  }

  .h4 {
    font-size: 12px;
    line-height: 16px;
  }

}
