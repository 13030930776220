@import '../../global.scss';

.row {
  display: flex;
  flex-wrap: wrap;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
}

.row,
.grid {
  gap: 30px;
  width: 100%;
  justify-content: center;
}

.mainInfo {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;

  .mainInfoCard {
    width: calc(50% - 30px) !important;
  }

  @media (max-width: 641px) {
    align-items: flex-start;
  }

  @media (max-width: 1051px) and (max-width: 576px) {
    flex-direction: column;
    align-items: center;
  }
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 100%;

  @media (max-width: 820px) {
    align-items: center;
  }
}
