.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 70px;
  margin-bottom: 100px;

  @media (max-width: 640px) {
    margin: 20px 0 20px 0;
    gap: 10px;
  }

  .textContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    @media (max-width: 640px) {
      gap: 10px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: -0.011em;
    color: #000000;

    @media (max-width: 640px) {
      font-size: 18px;
    }
  }

  p {
    max-width: 900px;
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    letter-spacing: 0.009em;
    color: #000000;
  }
}

.customParagraph {
  // align-items: flex-start;
  // width: 100%;

  .desc {
    font-weight: 500;
  }
}
.aboutImg {
  @media (max-width: 768px) {
    width: 100%;
  }
}
