.headerPosition {
  flex-grow: 1;
  position: sticky !important;
  height: 64px;
  z-index: 100;

  @media (max-width: 820px) {
    height: 56px;
  }

  .appBar {
    background-color: #fff;

    .toolBar {
      display: flex;
      justify-content: space-between;
      padding: 0 !important;

      .logo {
        max-width: 130px;
        cursor: pointer !important;
      }

      @media (max-width: 1050px) {
        display: none;
      }
    }

    .modileToolbar {
      display: none;

      .logo {
        max-width: 100px;
        max-height: 44px;
        cursor: pointer !important;
        margin-right: 5px;
      }

      @media (max-width: 1050px) {
        display: flex;
        justify-content: space-between;
        padding: 0px;
      }
    }
  }
}

.linkItem {
  // width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #303030;
  text-decoration: none;
  
  @media (max-width: 1200px) {
    font-size: 13px;
  }
}

.flexList {
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}

.select {
  @media (max-width: 1200px) {
    font-size: 13px !important;
  }
}

.burgerMenu {
  @media (max-width: 1050px) {
    display: none;
  }
}
