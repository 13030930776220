.pageWrapper {
  width: 100%;
  min-height: 100vh;
  position: relative;

  .flexWrapper {
    display: flex;
    width: 100%;

    .diagramWrapper {
      padding: 20px;
    }
  }

  .btnToDownload {
    position: fixed;
    right: 100px;
    top: 100px;

    padding: 10px 15px;
    background-color: rgb(65, 62, 160);
    color: #fff;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 10px;

    transform: scale(1.1);
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    transition: all 0.2s ease;

    &:hover {
      box-shadow: inset 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    }
  }
}

.pie {
  width: 20%;
}

.desc {
  text-align: center;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
