.titleStat {
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 150% !important;
  letter-spacing: -0.011em !important;
  color: #000000 !important;
  margin: 20px 0 !important;

  @media(max-width: 520px) {
    font-size: 18px !important;
  }
}
