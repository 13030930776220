.title {
  font-weight: 600 !important;
  margin-bottom: 30px !important;
  font-size: 25px !important;

  @media (max-width: 768px) {
    font-size: 20px !important;
  }
}

@mixin initialStyle {
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: -0.011em;
}

.prevPath {
  @include initialStyle;
  color: #a5a5a5;
  border-bottom: 1px solid #a5a5a5;
}
