@import "../../../global.scss";

.spBox {
  @include spaceBetweenBlock;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
}

.gap {
  column-gap: 40px;
  row-gap: 20px;
}

@media (max-width: 768px) {
  .containterOfInputs {
    flex-direction: column-reverse !important;

    & form {
      flex-direction: column !important;
      & > * {
        min-width: 100%;
      }
    }
  }

  .spBox {
    width: 100%;
    justify-content: center;
  }

  .formInput {
    width: 100%;
    margin-bottom: 20px;

    > div {
      width: 100% !important;

      > div {
        width: 100% !important;
      }
    }
  }

  .select {
    width: 100% !important;
  }
}

.select {
  width: 200px;
  height: 45px;
  border-radius: 4px !important;
  outline: none !important;

  > div {
    color: #706e6b;
    padding: 0 0 0 10px !important;
  }

  > svg > path {
    background: #fff !important;
  }
}

.input {
  width: 200px !important;
  border: 1px solid #706e6b !important;
  border-radius: 4px !important;
  padding: 0 !important;
  height: 43px !important;
  outline: none !important;
}

.clearBtn {
  border: 0.5px solid #706e6b !important;
  border-radius: 4px !important;
  outline: none !important;
  height: 45px;
  color: #706e6b !important;
  text-transform: none !important;
}
