@import '../../global.scss';

.teamList {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(235px, 285px));
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media (max-width: 640px) {
    justify-content: center;
  }
}

.conferenceList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  width: 100%;

  @media (max-width: 820px) {
    align-items: center;
  }
}

.noResult {
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.011em;
  color: #000000;
  text-align: center;

  margin-top: 70px;
}
